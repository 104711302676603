@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.register {
  position: relative;
  &__wrapper--reviews {
    &::before {
      content: "";
      position: absolute;
      top: 1.2rem;
      right: 1.2rem;
      height: 51px;
      width: 51px;
      // background-color: red;
      z-index: 1001;
      background-color: #f5f4f2;
    }
  }
}
// .mobile-nav {
//   display: none;
// }
