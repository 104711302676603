@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.card-links {
  &__wrapper {
    background-color: #f5f4f2;
    padding: 6rem 1rem 2rem 1rem;
    display: flex;
    align-items: center;

    height: 80vh;
    // @include breakpoint($sm2) {
    // }
    @include breakpoint($md) {
      padding: 8rem 0 2rem 0;
      height: 100%;
      height: 80vh;
      // min-height: 85vh;
    }
  }
  &__cta {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    button {
      background-color: #000;
      color: #fff;
      padding: 0.7rem 0.8rem;
      border: 0;
      min-width: 170px;
      font-weight: bolder;
      font-size: 1.1rem;
      letter-spacing: 1.2px;
    }
  }
}
