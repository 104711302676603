@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

html {
  overflow-x: hidden;
}
.profile {
  font-family: $main-font;
  &__wrapper {
    background-color: #fff;
    position: relative;
    z-index: 1050;
    min-height: 100vh;
    position: relative;
    z-index: 1033;
  }
  &__container {
    padding-bottom: 4rem;
    // @include breakpoint($md) {
    // }
    &__header {
      text-align: center;
      padding: 2rem 0;
      @include breakpoint($md) {
        padding: 2rem 0 1rem 0;
      }
      img {
        height: 60px;
      }
      form {
        margin: 1rem auto 0 auto;
        width: max-content;
        font-size: 1.1rem;
        .form-check.form-switch {
          @include breakpoint($md) {
            font-size: 1.2rem;
          }
        }
      }
    }
    &__details {
      border-top: 1px solid #eaeaea;
      border-bottom: 1px solid #eaeaea;
      padding: 1.5rem 0;
      width: 90%;
      margin: 0 auto;
      img {
        height: 110px;
        border-radius: 50%;
        @include breakpoint($md) {
          height: 200px;
          text-align: center;
        }
      }
      &__info {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 1.5rem;
        h3 {
          font-family: $main-font-semibold;
        }
        p {
          font-size: 1.1rem;
        }
        span {
          color: #a5a5a5;
          font-size: 1rem;
        }
      }
    }
    &__contact {
      padding: 2rem 0.5rem;
      border-bottom: 1px solid #eaeaea;
      margin: 0 auto;
      width: 90%;
      ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        @include breakpoint($md) {
          columns: 3;
        }
        li {
          font-size: 1.2rem;
          letter-spacing: 1px;
          @include breakpoint($md) {
            text-align: center;
          }
          span {
            text-transform: uppercase;
            font-family: $main-font-bold;
            text-align: center;
            display: inline-block;
            margin-right: 1rem;
            font-size: 1.1rem;
            width: 11px;
          }
        }
      }
    }
    &__cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 1fr min-content;
      grid-gap: 1rem;
      width: unset;
      padding: 2rem 1rem;
      .contact-card {
        text-align: center;
        a {
          color: #000;
          text-decoration: none;
          text-transform: capitalize;
          img {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
    button {
      background-color: #000;
      border: 0;
      color: #fff;
      margin: 0 auto;
      display: block;
      border-radius: 25px;
      font-size: 1.2rem;
      font-family: $main-font-semibold;
      padding: 0.8rem 1.7rem;
    }
  }
}
