@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.register {
  &__container {
    background-color: #fff;
    border-top: 6px solid #000;
    padding: 2rem 1rem;
    margin: 0 auto;
    border-radius: 10px;
    width: 100%;
    @include breakpoint($md) {
      padding: 2.1rem;
      width: 60%;
    }
    img {
      height: 80px;
      display: block;
      margin: 0 auto;
      margin-bottom: 2rem;
    }
    h3 {
      font-family: $main-font-semibold;
    }
    h3,
    p {
      @include breakpoint($md-down) {
        text-align: center;
      }
    }
    form {
      margin-top: 0.5rem;
      @include breakpoint($md-down) {
        margin-top: 1rem;
      }
    }
    a {
      color: #000;
    }
    label {
      font-weight: bolder;
    }
    input {
      &:not([type="checkbox"]) {
        display: flex;
        background-color: #fbfbfb;
        border: 1px solid #dad8d8;
        border-radius: 0;
        padding: 0.8rem 0.6rem;
      }
      &[type="file"] {
        display: block;
        margin: 0 auto 1rem auto;
        max-width: 100%;
      }
    }
    button {
      background-color: #000;
      border: 0;
      color: #fff;
      font-size: 1.1rem;
      padding: 1rem 0;
      margin: 1rem 0;
      width: 100%;
      position: relative;
      z-index: 999;
      &:disabled {
        background-color: #8f8f8f;
        cursor: not-allowed;
      }
    }
    .avatar {
      height: 150px;
      border-radius: 50%;
      margin-bottom: 1rem;
      @include breakpoint($md) {
        height: 150px;
      }
    }
  }
  &__logo {
    @include breakpoint($md-3) {
      display: none !important;
    }
  }
  &__wrapper {
    background-color: #f5f4f2;
    padding: 5rem 1rem 2rem 1rem;
    display: flex;
    align-items: center;
    @include breakpoint($md) {
      padding: 140px 0 2rem 0;
      min-height: 60vh;
    }
    &--reviews {
      min-height: 70vh;
      @include breakpoint($md) {
        min-height: 90vh;
      }
      & > div {
      }
    }
  }
}
