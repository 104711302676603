// Color fonts
$grey: #a9a8a9;
$dark-grey: #dcdcdc;
$light-grey: #f4f4f4;

// Font variables
$main-font: "Aeonik Regular";
$main-font-semibold: "Aeonik Medium";
$main-font-bold: "Aeonik Bold";
$main-font-light: "Aeonik Light";

@font-face {
  font-family: "Aeonik Regular";
  src: local("Aeonik-Regular"),
    url(../../assets/fonts/Aeonik-Regular.otf) format("opentype");
}
@font-face {
  font-family: "Aeonik Bold";
  src: local("AeonikBold"),
    url(../../assets/fonts/Aeonik-Bold.otf) format("opentype");
}
@font-face {
  font-family: "Aeonik Medium";
  src: local("AeonikMedium"),
    url(../../assets/fonts/Aeonik-Medium.otf) format("opentype");
}
@font-face {
  font-family: "Aeonik Light";
  src: local("AeonikLight"),
    url(../../assets/fonts/Aeonik-Light.otf) format("opentype");
}
