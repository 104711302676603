@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.grey {
  background-color: #f0f0f0;
  height: 1px;
  width: 106%;
  margin-left: -2rem;
}

.account-profile {
  &__wrapper {
    background-color: #f5f4f2;
    padding: 6rem 1rem 2rem 1rem;
    display: flex;
    align-items: center;
    position: relative;
    @include breakpoint($sm2) {
      min-height: 80vh;
    }
    @include breakpoint($md) {
      padding: 8rem 0 2rem 0;
      // height: 100%;
      min-height: unset;
      // min-height: 85vh;
    }
  }
  &__container {
    // padding: 0 2rem;
    background-color: #fff;
    // border-top: 6px solid #000;
    margin: 0 auto;
    border-radius: 24px;
    width: 100%;
    box-shadow: 0 0 7px 3px rgba(198, 198, 198, 0.32);
    @include breakpoint($md) {
      min-height: 500px;
    }
    @include breakpoint($md-2) {
      // padding: 2.1rem 1rem;
      width: 90%;
    }
    @include breakpoint($md-4) {
      width: 90%;
    }
    .container {
      padding: 0;
    }
    &__header {
      padding-bottom: 1rem;
      padding: 1.5rem 0.8rem 1.3rem 0.8rem;
      @include breakpoint($md-2) {
        padding: 2.1rem 2rem 1rem 2rem;
      }
      h3 {
        font-family: $main-font-semibold;
      }
      form {
        margin-top: 1rem;
        font-size: 1.1rem;
        .form-check.form-switch {
          @include breakpoint($md) {
            font-size: 1.2rem;
          }
        }
      }
    }
    &__details {
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
      padding: 1rem 0.8rem;
      @include breakpoint($md) {
        padding: 1rem 0;
      }
    }
    &__personal-data {
      @include breakpoint($md) {
        padding: 0 2rem;
      }
      // @include breakpoint($lg) {
      //   padding: 0 2rem;
      // }
      &__media {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 1rem;
        &__container {
          background-color: #eaeaea;
          border-radius: 50%;
          height: 230px;
          width: 230px;
          display: flex;
          align-items: center;
          overflow: hidden;
          img {
            border-radius: 50%;
            height: auto;
            width: 100%;
          }
        }
        input {
          background-color: #fff !important;
          width: min-content;
          width: 260px;
        }
        button {
          height: 40px !important;
          width: 90% !important;
        }
      }
      h3 {
        font-family: $main-font-semibold;
      }
      form {
        h5 {
          font-family: $main-font-semibold;
          margin-bottom: 1rem;
        }
        input,
        select {
          background: #eaeaea;
          border-radius: 10px;
          padding: 0.8rem 1rem;
          &.is-invalid {
            border-color: #dc3545;
            padding-right: calc(1.5em + 0.75rem);
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right calc(0.375em + 0.1875rem) center;
            background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
          }
        }
        select {
          background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
          background-repeat: no-repeat, repeat;
          background-position: right 1rem center;
          border-radius: 0.25rem;
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          border: 1px solid #d8e2ef;
          color: #344040;
          background-size: 10px 12px;
        }
        button {
          background-color: #000;
          color: #fff;
          border: 0;
          border-radius: 15px;
          text-align: center;
          margin: 0 auto;
          display: block;
          font-family: $main-font-bold;
          letter-spacing: 1.3px;
          height: 51px;
          width: 100%;
          transition: all 0.2s ease-in-out;
          &:disabled {
            background-color: #b5b5b5;
          }
          &:hover {
            background-color: #2b2b2b;
          }
          @include breakpoint($lg) {
            height: 51px;
            width: 369px;
            margin-bottom: 1.5rem;
          }
        }
      }
    }
  }
}
