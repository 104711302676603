@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.footer {
  background-color: $dark-grey;
  padding: 3rem 1rem 2rem 1rem;
  @include breakpoint($md) {
    padding: 3rem 0;
  }
  img {
    @include breakpoint($md-down) {
      height: 80px;
    }
  }
  a {
    color: #868686;
    text-decoration: none;
  }
  span {
    color: #868686;
    font-size: 0.9rem;
  }
  .btn-float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 20px;
    background-color: #25d366;
    border-radius: 50px;
    text-align: center;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: #000;
    }
    img {
      height: 29px;
    }
  }
}
