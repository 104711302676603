@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.mobile-nav {
  position: fixed;
  top: 0;
  right: 0;
  clip-path: circle(25px at calc(100% - 45px) 45px);
  transition: all 0.1s ease-in-out;
  z-index: 1000;
  width: 0;
  height: 10%;
  &.active {
    height: 100%;
    width: 100%;
    background-color: #d0d0d0;
    clip-path: circle(75%);
    span {
      &:nth-child(1) {
        top: 50% !important;
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:nth-child(2) {
        display: none;
      }
      &:nth-child(3) {
        top: 50% !important;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
    .links {
      a,
      p {
        display: block;
      }
    }
  }
  .ham-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: #000;
    border-radius: 50%;
    span {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 2px;
      background-color: #f5f5f5;
      transition: all 0.1s ease-in-out;
      &:nth-child(1) {
        top: 30%;
      }
      &:nth-child(2) {
        top: 50%;
      }
      &:nth-child(3) {
        top: 70%;
      }
    }
  }
  .links {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 250px;
    a {
      position: relative;
      display: block;
      margin: 20px 0;
      font-size: 1.2rem;
      font-weight: 600;
      text-decoration: none;
      color: #f5f5f5;
      text-transform: uppercase;
      letter-spacing: 5px;
      display: none;
    }
    p {
      display: none;
    }
  }
}
.logout-mobile {
  p {
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 5px;
  }
}
