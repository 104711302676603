@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.privacy {
  @include breakpoint($md) {
    margin-top: 116px;
  }
  &__hero {
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    text-align: center;
    height: 100px;
    @include breakpoint($md) {
      height: 150px;
    }
  }
  &__content {
    text-align: justify;
    p {
      font-size: 17px;
      margin-bottom: 1rem;
    }
    a {
      color: #000;
    }
    h6 {
      font-weight: bolder;
      opacity: 0.7;
    }
  }
}
