@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.custom-nav {
  display: block !important;
}

.home {
  &__wrapper {
  }
  &__carousel {
    .carousel {
      height: 660px;
      @include breakpoint($md) {
        height: 800px;
      }
      &-inner {
        height: 100%;
      }
      &-control-prev {
        width: 7%;
        @include breakpoint($lg) {
          width: 5%;
        }
      }
      &-control-next {
        width: 7%;
        @include breakpoint($lg) {
          width: 5%;
        }
      }
      &-caption {
        color: #000;
        text-align: left;
        bottom: 7%;
        left: 7%;
        @include breakpoint($md) {
          bottom: 25%;
        }
        @include breakpoint($lg) {
          bottom: 20%;
        }
        h1 {
          font-size: 3rem;
          // font-size: 2.5rem;
          width: 70%;
          // @include breakpoint($ipad-port) {
          //   font-size: 5rem;
          // }
          @include breakpoint($md) {
            width: 48%;
            font-size: 4rem;
          }
          @include breakpoint($lg) {
            width: 48%;
            font-size: 5rem;
          }
        }
        p {
          font-size: 2rem;
          font-family: $main-font-light;
          margin: 0.4rem 0;
          width: 60%;
          @include breakpoint($md) {
            width: 50%;
            font-size: 2rem;
          }
        }
        a {
          background-color: #000;
          border-radius: 40px;
          color: #fff;
          padding: 0.8rem 4rem;
          text-decoration: none;
          display: block;
          width: max-content;
          @include breakpoint($md) {
            // margin-left: 7rem;
          }
        }
      }
      &-item--1 {
        background-image: url("../../assets/img/gueya-3.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
        height: 100%;
      }
      &-item--2 {
        background-image: url("../../assets/img/gueya_5.jpg");
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        height: 100%;
        @include breakpoint($md) {
          background-position: center;
        }
      }
      &-item--3 {
        background-image: url("../../assets/img/gueya_12.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
        height: 100%;
      }
    }
  }
  &__hero {
    background-image: url("../../assets/img/gueya-3.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    height: 800px;
    padding: 2rem 1.5rem;
    position: relative;
    @include breakpoint($md) {
      background-position: top;
      padding: 2rem;
      background-image: url("../../assets/img/gueya-3.jpg");
      // position: relative;
      // top: 40px;
    }
    @include breakpoint($lg) {
      height: 900px;
    }
    @include breakpoint($xl) {
      background-position: center;
      height: 1100px;
    }
    &__slide {
      position: absolute;
      right: -3rem;
      top: 40%;
      transform: rotate(-90deg);
      width: 250px;
      @include breakpoint($md-down) {
        display: none;
      }
      p {
        width: 100% !important;
        font-size: 1.1rem !important;
        display: inline;
        font-family: "Aeonik Bold";
      }
      & > div {
        border: 2px solid #000;
        width: min-content;
        border-radius: 50%;
        padding: 0.4rem 1rem;
        display: inline-block;
        margin-right: 1rem;
      }
      img {
        transform: rotate(90deg);
        max-height: unset !important;
        margin: 0 !important;
        height: 35px !important;
      }
    }
    h1,
    h2 {
      // color: #fff;
      // width: 65%;
      // @include breakpoint($md) {
      //   text-align: center;
      // }
    }
    h1 {
      font-size: 2.5rem;
      width: 65%;
      @include breakpoint($md) {
        width: 48%;
        font-size: 7rem;
      }
      @include breakpoint($lg) {
        width: 48%;
        font-size: 5rem;
      }
    }
    p {
      font-size: 2rem;
      font-family: $main-font-light;
      margin: 0.4rem 0;
      width: 60%;
      @include breakpoint($md) {
        width: 50%;
        font-size: 2rem;
      }
    }
    a {
      background-color: #000;
      border-radius: 40px;
      color: #fff;
      padding: 0.8rem 4rem;
      text-decoration: none;
      display: block;
      @include breakpoint($md) {
        margin-left: 7rem;
      }
    }
    img {
      max-height: 100px;
      margin: 1rem 0;
      @include breakpoint($md) {
        margin: 2rem 0;
        max-height: 150px;
      }
    }
    &__wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      // padding: 1rem;
      // height: 60vh;
      // @include breakpoint($md) {
      //   padding: 3rem;
      //   height: 700px;
      // }
      // @include breakpoint($xl) {
      //   height: 850px;
      // }
    }
  }
}

.about-us {
  position: relative;
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 4rem 0 4rem 0;
    width: 100%;
    @include breakpoint($md-down) {
      padding: 4rem 1rem 2rem 1rem;
    }
    img {
      display: none;
      @include breakpoint($md) {
        display: block;
        height: 40px;
        margin: 1rem 2rem 0 0;
      }
    }
    & > div {
      @include breakpoint($md) {
        width: 38%;
      }
      h2 {
        margin: 0 auto;
        font-size: 2.5rem;
        font-family: $main-font-semibold;
        @include breakpoint($md) {
          font-size: 4rem;
          // width: 50%;
        }
      }
      p {
        margin: 1.5rem 0 0 0;
        font-size: 1.7rem;
        @include breakpoint($md) {
          font-size: 2rem;
          width: 90%;
        }
        span {
          font-family: $main-font-bold;
        }
      }
    }
  }
  &__media {
    display: flex;
    justify-content: center;
    img {
      height: 150px;
      // height: 230px;
      @include breakpoint($md-4) {
        top: 35rem;
        left: 25%;
        height: 380px;
      }
      &:nth-child(2) {
        margin-left: -1.5rem;
      }
      &:nth-child(3) {
        height: 80px;
        width: auto;
        @include breakpoint($md) {
          height: 220px;
        }
      }
    }
    // img {
    //   position: absolute;
    //   top: 25rem;
    //   left: -1%;
    //   height: 230px;
    //   // display: none;
    //   // @include breakpoint($ipad-land) {
    //   //   top: 32rem;
    //   //   left: 25%;
    //   //   height: 350px;
    //   // }
    //   @include breakpoint($md-4) {
    //     top: 35rem;
    //     left: 25%;
    //     height: 350px;
    //   }
    //   @include breakpoint($md-3) {
    //     top: 32rem;
    //   }
    //   @include breakpoint($md-1) {
    //     top: 29rem;
    //   }
    //   @include breakpoint($lg) {
    //     top: 30rem;
    //     left: 25%;
    //     height: 350px;
    //   }
    //   @include breakpoint($xl) {
    //     position: absolute;
    //     display: block;
    //     height: 460px;
    //     top: 25rem;
    //     left: 20%;
    //   }
    //   &:nth-child(2n) {
    //     left: 62%;
    //     @include breakpoint($lg) {
    //       left: 55%;
    //     }
    //     @include breakpoint($xl) {
    //       top: 25rem;
    //     }
    //   }
    // }
  }
  &__content {
    background-color: #000;
    padding: 5rem 0 2rem 0;
    color: #fff;
    margin-top: -8rem;
    @include breakpoint($md) {
      padding: 5rem 0 1rem 0;
      margin-top: -12rem;
    }
    @include breakpoint($lg) {
      margin-top: -13rem;
      padding: 7rem 0 4rem 0;
    }
    @include breakpoint($xl) {
      padding: 14rem 0 4rem 0;
    }
    p {
      // margin: 0 auto;
      font-family: $main-font-light;
      font-size: 1.2rem;
      line-height: 1.7;
      margin-top: 0.5rem;
      span {
        font-family: $main-font-bold;
      }
      @include breakpoint($lg) {
        // width: 50%;
        font-size: 1.2rem;
        margin: 0.5rem 0 0 0;
        display: block;
      }
      @include breakpoint($xl) {
        font-size: 1.1rem;
      }
    }
    a {
      background-color: #fff;
      color: #000;
      text-decoration: none;
      border-radius: 20px;
      padding: 0.7rem 2rem;
      display: block;
      margin: 1rem 0 0 0;
      width: 100%;
      text-align: center;
      @include breakpoint($lg) {
        width: max-content;
        margin-left: 20rem;
      }
    }
    &__get {
      margin-top: 4rem;
      @include breakpoint($md) {
        margin-top: 8rem;
      }
      h1 {
        font-size: 2.5rem;
        span {
          font-size: 1rem;
        }
        @include breakpoint($md) {
          font-size: 3rem;
        }
      }
      a {
        background-color: #fff;
        color: #000;
        text-decoration: none;
        border-radius: 20px;
        padding: 0.7rem 2rem;
        display: block;
        margin: 2rem 0;
        width: max-content;
        font-weight: bolder;
        font-size: 1.2rem;
        @include breakpoint($md) {
          margin: 2rem 0;
        }
      }
      img {
        margin-top: 2rem;
        @include breakpoint($ipad-port) {
          max-height: 400px;
          display: block;
          margin: 0 auto;
        }
        @include breakpoint($md) {
          max-height: unset !important;
          height: 90%;
          width: auto;
        }
      }
    }
  }
}
.about-us__float {
  position: absolute;
  left: 1rem;
  top: 2rem;
  font-size: 0.9rem;
  @include breakpoint($md) {
    top: 20rem;
  }
  @include breakpoint($lg) {
    // width: 100%;
    left: 1.5rem;
  }
}

.how-it-works {
  @include breakpoint($md) {
    padding-bottom: 4rem;
  }
  position: relative;
  &::after {
    content: "";
    background-image: url("../../assets/img/gueya_vector.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    height: 130px;
    width: 130px;
    position: absolute;
    left: -4rem;
    bottom: 20rem;
    @include breakpoint($md) {
      height: 180px;
      width: 180px;
    }
  }
  &__content {
    padding: 4rem 0;
    @include breakpoint($md) {
      padding: 8rem 0 0 9rem;
      position: relative;
      z-index: 9;
    }
    h2 {
      font-family: $main-font-bold;
      font-size: 2.5rem;
      @include breakpoint($md) {
        font-size: 3.5rem;
        margin-bottom: 2rem;
        width: 60%;
      }
    }
    h4 {
      font-family: $main-font-bold;
      @include breakpoint($md-down) {
        font-size: 2rem;
      }
    }
    p {
      @include breakpoint($md) {
        width: 80%;
      }
    }
    a {
      background-color: #000;
      color: #fff;
      text-decoration: none;
      border-radius: 20px;
      padding: 0.7rem 5rem;
      display: block;
      margin-top: 2rem;
      width: max-content;
      z-index: 9;
      position: relative;
    }
  }
  img {
    width: 100%;
    height: auto;
    position: relative;
    top: -5rem;
    @include breakpoint($ipad-port) {
      width: 70%;
      max-height: 700px;
      display: block;
      margin: 0 auto;
      right: -0.7rem;
    }

    @include breakpoint($md) {
      max-height: unset !important;
      height: auto;
      width: 100%;
      top: 0;
    }
    @include breakpoint($lg) {
      position: relative;
      right: 7rem;
      height: 950px;
      width: auto;
    }
    // @include breakpoint($lg) {
    //   right: 3.5rem;
    // }
    @include breakpoint($lg2) {
      right: 0;
    }
    @include breakpoint($xl) {
      right: -6rem;
    }
  }
}

.benefits {
  background-image: linear-gradient(
    90deg,
    #e1e5e6 2%,
    #e8eced 21%,
    #dadedf 75%
  );
  padding-top: 4rem;
  @include breakpoint($md) {
    background-image: linear-gradient(90deg, #fbfcfd 13%, #f0f1f3 84%);
  }
  h2 {
    font-size: 2.5rem;
    @include breakpoint($md) {
      font-size: 4rem;
      width: 50%;
    }
  }
  p {
    font-size: 1.5rem;
    font-family: $main-font-light;
    margin-top: 1rem;
    @include breakpoint($md) {
      font-size: 2rem;
      margin-top: 0;
    }
  }
  &-cta {
    background-image: linear-gradient(
      90deg,
      #dde1e2 8%,
      #dadedf 51%,
      #d9ddde 83%
    );
    padding: 4rem 0;
    @include breakpoint($md) {
      background-image: linear-gradient(90deg, #fbfcfd 13%, #f0f1f3 84%);
    }
    h3 {
      font-size: 2.5rem;
      @include breakpoint($md) {
        font-size: 4rem;
      }
    }
    a {
      background-color: #000;
      border-radius: 40px;
      color: #fff;
      padding: 0.8rem 4rem;
      text-decoration: none;
      display: block;
      width: max-content;
      margin-top: 1.5rem;
      @include breakpoint($md) {
        margin-top: 2rem;
      }
    }
  }
}
.q-a {
  background-color: #000;
  overflow: hidden;
  &__media {
    img {
      margin-top: -3rem;
      @include breakpoint($md) {
        margin-top: -12rem;
      }
    }
  }
  &__content {
    padding-bottom: 4rem;
    @include breakpoint($md) {
      padding-bottom: 4rem;
      margin-top: -12rem;
    }
    h3 {
      color: #fff;
      font-size: 2.5rem;
      @include breakpoint($md) {
        font-size: 4.5rem;
        width: 30%;
      }
    }
    .accordion {
      margin-top: 1rem;
      @include breakpoint($md) {
        margin-top: 3rem;
      }
      &-header {
        button {
          background-color: #000;
          padding-left: 0;
          color: #fff;
          font-family: $main-font-semibold;
          @include breakpoint($md-down) {
            font-size: 1.2rem;
          }
        }
      }
      &-body {
        background-color: #000;
        padding-left: 0;
        color: #fff;
        font-family: $main-font-light;
      }
    }
  }
}
.enterprise {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.73),
      hsla(0, 0, 0, 0.274)
    ),
    url("../../assets/img/empresas_gueya.jpg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 4rem;
  padding-bottom: 5rem;
  @include breakpoint($md) {
    padding-top: 5rem;
    padding-bottom: 3rem;
  }
  h3,
  p {
    color: #fff;
  }
  h3 {
    font-size: 2.5rem;
    @include breakpoint($md) {
      font-size: 4rem;
      width: 80%;
    }
  }
  p {
    font-size: 1rem;
    @include breakpoint($md) {
      font-size: 1.5rem;
      width: 80%;
      margin-top: 3rem;
    }
  }
  a {
    background-color: #fff;
    color: #000;
    text-decoration: none;
    border-radius: 20px;
    padding: 0.7rem 2rem;
    display: block;
    margin-top: 1rem;
    width: max-content;
    @include breakpoint($md) {
      margin: 3rem 0;
    }
  }
  &__media {
    .gueya-card-carousel {
      background-image: linear-gradient(180deg, #eff1f1 0%, #cfd0d1 100%);
      border-radius: 30px;
      height: 200px;
      width: 300px;
      margin: 0 auto;
      @include breakpoint($md) {
        border-radius: 40px;
        height: 280px;
        width: 430px;
        margin: unset;
      }
      @include breakpoint($lg) {
        height: 300px;
        width: 480px;
      }
      .carousel {
        height: 200px;
        @include breakpoint($md) {
          height: 280px;
        }
        &-inner,
        &-item {
          height: 100%;
        }
        &-item-inner {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: auto !important;
            max-height: 50px;
            @include breakpoint($lg) {
              max-height: 80px;
              width: auto !important;
            }
          }
          &--1 {
            img {
              max-height: 120px;
              @include breakpoint($lg) {
                max-height: 200px;
              }
            }
          }
          &--2 {
            img {
              max-height: 80px;
              @include breakpoint($lg) {
                max-height: 120px;
              }
            }
          }
          &--3 {
            img {
              max-height: 150px;
              @include breakpoint($lg) {
                max-height: 220px;
              }
            }
          }
        }
        // &-item {
        //   display: flex;
        //   align-items: center;
        // }
      }
    }
    img {
      @include breakpoint($ipad-port) {
        max-height: 400px;
        display: block;
        margin: 0 auto;
      }
      // @include breakpoint($md) {
      //   // max-height: unset !important;
      //   height: auto;
      //   width: 100%;
      // }
    }
    p {
      margin-top: 0;
      font-size: 1rem;
      @include breakpoint($lg) {
        float: right;
        font-size: 1rem;
        position: relative;
        left: 3rem;
        top: 3rem;
      }
    }
  }
  &__footer {
    @include breakpoint($md) {
      margin-top: 2rem;
    }
    @include breakpoint($md-down) {
      // position: relative;
      // top: -7rem;
      // left: 0;
      display: none;
    }
  }
  &__content {
    @include breakpoint($md-down) {
      position: relative;
      top: 1.5rem;
      left: 0;
      right: 1rem;
    }
  }
}
