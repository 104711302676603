@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.custom-nav {
  background-color: transparent;
  transition: all 0.2s ease-in;
  .navbar {
    transition: all 0.2s ease-in;
    &-brand {
      img {
        height: 75px;
        @include breakpoint($md) {
          height: 90px;
        }
      }
    }
  }
  &--light {
    .nav-link {
      color: #fff;
      &.active {
        color: #fff !important;
      }
    }
  }
  &-active {
    background-color: #000;
    .nav-link {
      color: #fff;
      &.active {
        color: #fff !important;
      }
    }
    // p {
    //   color: #fff;
    // }
    .navbar-brand {
      img {
        height: 70px;
      }
    }
  }
  .nav {
    &-link {
      @include breakpoint($md) {
        margin-left: 0.5rem;
      }
    }
  }
  p {
    &:hover {
      cursor: pointer;
    }
  }
  .logout {
    p {
      // color: #000;
      text-transform: unset;
      letter-spacing: unset;
      font-size: 1rem;
    }
    a {
      padding: 0 !important;
      img {
        height: 23px;
        margin-left: 0 !important;
        margin-right: 0.6rem;
      }
    }
  }
  &__brands {
    display: flex;
    align-items: center;
    img {
      height: 20px;
      margin-left: 0.6rem;
    }
  }
}
