@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.register__container {
  button {
    margin-bottom: 0;
  }
}
.register__wrapper--reviews {
  &__trip-results {
    margin-top: -4.5rem;
    background-color: #fbfbfb;
    z-index: 1000;
    position: relative;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
    @include breakpoint($md) {
      margin-top: -6rem;
    }
    ul {
      // padding-left: 0.5rem;
      padding-left: 0;
      list-style: none;
      li {
        border-bottom: 1px solid #bebebe;
        padding: 0.3rem 0 0.3rem 0.5rem;
        &:hover {
          cursor: pointer;
          background-color: rgba(195, 195, 195, 0.216);
        }
      }
    }
  }
  &__reset {
    font-size: 0.8rem;
    font-style: italic;
    text-decoration: underline;
    text-align: left !important;
  }
}

input {
  &:disabled {
    background-color: #e5e5e5 !important;
  }
}
